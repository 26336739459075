@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";

body {
  //width: 1080px;
  font-family: Georgia, "Times New Roman", Times, serif;
  margin: 20px auto 25px;
  clear: both;
  background-color: #eeeeee;
}

h1 {
  color: #FF0000;
  font-size: 36px;
}

a {
  text-decoration: none;
  color: #0000FF;

  &:hover {
    color: #0099FF;
  }

  &:active {
    color: #0000FF;
  }
}

#container {
  background-color: #FFFFFF;
}

.button-container {
  padding: 20px;
}

.button-image {
  //display: flex;
  align-items: center;
}
